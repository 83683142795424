/**
 * @generated SignedSource<<15d3f090ce3b950e431597d8dc5d1cde>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
export type SubscriberUploadSubscriptionFlow = "SUBSCRIPTION_FLOW_DOUBLE_OPT_IN" | "SUBSCRIPTION_FLOW_SILENT_OPT_IN" | "SUBSCRIPTION_FLOW_SINGLE_OPT_IN" | "SUBSCRIPTION_FLOW_UNKNOWN" | "%future added value";
export type SubscriberUploadSubscriptionType = "SUBSCRIPTION_TYPE_EMAIL_CHECKOUT_ABANDONED" | "SUBSCRIPTION_TYPE_EMAIL_MARKETING" | "SUBSCRIPTION_TYPE_EMAIL_TRANSACTIONAL" | "SUBSCRIPTION_TYPE_TEXT_CHECKOUT_ABANDONED" | "SUBSCRIPTION_TYPE_TEXT_MARKETING" | "SUBSCRIPTION_TYPE_TEXT_TRANSACTIONAL" | "SUBSCRIPTION_TYPE_UNKNOWN" | "%future added value";
export type SubscriberUploadUpdateStrategy = "SUBSCRIBER_UPDATE_STRATEGY_OVERWRITE" | "SUBSCRIBER_UPDATE_STRATEGY_REMOVE_DUPLICATES" | "SUBSCRIBER_UPDATE_STRATEGY_UNKNOWN" | "SUBSCRIBER_UPDATE_STRATEGY_UPDATE_EXISTING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type getSubscriberFlowLabel_SettingsUI_SubscriberUploadJob$data = {
  readonly parameters: {
    readonly strategy: SubscriberUploadUpdateStrategy;
    readonly subscriptionTypes: SubscriberUploadSubscriptionType;
  };
  readonly subscriptionFlow: SubscriberUploadSubscriptionFlow;
  readonly " $fragmentType": "getSubscriberFlowLabel_SettingsUI_SubscriberUploadJob";
};
export type getSubscriberFlowLabel_SettingsUI_SubscriberUploadJob$key = {
  readonly " $data"?: getSubscriberFlowLabel_SettingsUI_SubscriberUploadJob$data;
  readonly " $fragmentSpreads": FragmentRefs<"getSubscriberFlowLabel_SettingsUI_SubscriberUploadJob">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "getSubscriberFlowLabel_SettingsUI_SubscriberUploadJob"
};

(node as any).hash = "3a207e4dddf11e9c7e3902dc5b929f08";

export default node;
